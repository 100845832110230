import request from './request'
import Qs from "qs";
// 首页数据的请求
export const GetHomeList = params => request.post("/getHomeData",Qs.stringify(params))
//登录 首页数据
export const GetHomeList2 = params => request.post("/getHomeData2",Qs.stringify(params))

// 获取搜索弹出框的数据
export const GetSearchPopuData = () => request.get("/search/index")

// 获取实时搜索的数据的列表
// export const GetSearchTipsListData = (xxx) => request.get("/search/helper", {
//   params:xxx
// })
export const GetSearchTipsListData = params => request.get("/search/helper", {
  params
})

// 搜索商品列表数据
export const GetGoodsListData = params => request.get("/goods/list", {
  params
})

// 清除历史记录
export const ClearHistory = () => request.post("/search/clearhistory")





// 商品详情
export const GetGoodsDetail = params => request.get("/goods/detail", {
  params
})


// 相关产品
export const GetGoodsReletedDetail = params => request.get("/goods/related", {
  params
})

// 获取购物车产品数量
export const GetGoodsCount = () => request.get("/cart/goodscount")


// 加入购物车
export const AddProductToCart = (params) => request.post("/cart/add", Qs.stringify(params))


// 获取购物车数据
export const GetCartListData = () => request.get("/cart/index")

// 点击切换商品选中状态（含全选）
export const ChangeGoodChecked = (params) => request.post("/cart/checked", Qs.stringify(params))


// 步进器
export const StepperChangeData = (params) => request.post("/cart/update", Qs.stringify(params))


// 购物车删除商品
export const DeleteGood = (params) => request.post("/cart/delete", Qs.stringify(params))






// 登录请求
export const GoLogin = (params) => request.post("/loginByWeb", Qs.stringify(params))
//注册
export const GoRegister = (params) => request.post("/register", Qs.stringify(params))
//请求免费听课课程列表
export const onCourseList = (params) => request.post("/auditionList",Qs.stringify(params))
//请求免费听课课程详情
export const onCourseDetail = (params) => request.post("/lessionList", Qs.stringify(params))
//获取微信分享参数
export const onShareUrl = (params) => request.post("/onShareUrl", Qs.stringify(params))
//获取考试列表
export const onCateList = (params) => request.post("/onCateList",Qs.stringify(params))
export const onCateList2 = (params) => request.post("/onCateList2",Qs.stringify(params))
//选择考试、科目
export const groupSubjectPost = (params) => request.post("/groupSubjectPost",Qs.stringify(params))
//获取项目考试时间
export const examinationTime = (params) => request.post("/examinationTime",Qs.stringify(params))
//学习中心收费课程列表
export const myCommodityList = (params) => request.post("/myCommodityList",Qs.stringify(params))
//免费商品课程列表
export const commodityListFree = (params) => request.post("/myCommodityFreeList",Qs.stringify(params))
//领取免费商品
export const freeCommodityOrder = (params) => request.post("/freeCommodityOrder",Qs.stringify(params))
//查询课程签署协议
export const courseAgreement = (params) => request.post("/courseAgreement",Qs.stringify(params))
//学习周报
export const commodityStudyReport = (params) => request.post("/commodityStudyReport",Qs.stringify(params))
//课程商品课程列表
export const courseList = (params) => request.post("/getCommodityCourseList",Qs.stringify(params))
//课程商品免费课程列表
export const courseFreeList = (params) => request.post("/getCommodityCourseFreeList",Qs.stringify(params))
//获取课程播放页数据
export const getCommodityCourseCoursewareList = (params) => request.post("/getCommodityCourseCoursewareList",Qs.stringify(params))
//获取视频url
export const getCoursewareInfo = (params) => request.post("/coursewareInfo",Qs.stringify(params))
//获取课件播放时长
export const coursewareDuration = (params) => request.post("/coursewareDuration",Qs.stringify(params))
//获取用户基本信息
export const onInfo = (params) => request.post("/memberInfo",Qs.stringify(params))
//题库轮播图
export const questionBanner = (params) => request.post("/getQuestionBanner",Qs.stringify(params))
//选课中心
export const selectCommodityList = (params) => request.post("/selectCommodityList",Qs.stringify(params))
//选课中心 登录
export const selectCommodityList2 = (params) => request.post("/selectCommodityList2",Qs.stringify(params))
//商品详情
export const getCommodityDetail = (params) => request.post("/getCommodityDetail",Qs.stringify(params))
//客服
export const informationDetail = (params) => request.post("/informationDetail",Qs.stringify(params))
//首页推荐课程
export const getCommodityIndexList = (params) => request.post("/getCommodityIndexList",Qs.stringify(params))
//app登录
export const appLogin = (params) => request.post("/appLogin",Qs.stringify(params))
//版本
export const getEdition = (params) => request.post("/getEdition",Qs.stringify(params))
//协议
export const websiteInfo = (params) => request.post("/websiteInfo",Qs.stringify(params))
//重新获取项目、科目id、科目名称
export const getCateNewUser = (params) => request.post("/getCateNewUser",Qs.stringify(params))
//试听播放课件列表
export const getCourseWareVideoUrl = (params) => request.post("/getCourseWareVideoUrl",Qs.stringify(params))
//答疑提交
export const repliedPost = (params) => request.post("/repliedAdd",Qs.stringify(params))
//答疑课件列表
export const repliedList = (params) => request.post("/repliedList",Qs.stringify(params))
//答疑详情
export const repliedInfo = (params) => request.post("/getRepliedInfo",Qs.stringify(params))
//答疑列表
export const repliedAllList = (params) => request.post("/repliedAllList",Qs.stringify(params))
//笔记列表
export const noteList = (params) => request.post("/noteList",Qs.stringify(params))
//笔记添加
export const noteAdd = (params) => request.post("/noteAdd",Qs.stringify(params))
//删除笔记
export const noteDel = (params) => request.post("/noteDel",Qs.stringify(params))
//笔记详情 不使用
export const getNoteInfo2 = (params) => request.post("/getNoteInfo2",Qs.stringify(params))
//笔记修改详情
export const getNoteInfo = (params) => request.post("/getNoteInfo",Qs.stringify(params))
//笔记列表
export const noteAll = (params) => request.post("/noteAll",Qs.stringify(params))
//题库首页
export const questionIndexData = (params) => request.post("/questionIndexData",Qs.stringify(params))
//题库首页商品
export const getCommodityQuestionList = (params) => request.post("/getCommodityQuestionList",Qs.stringify(params))
//题库试卷科目
export const testPaperSubjectList = (params) => request.post("/getTestPaperSubjectList",Qs.stringify(params))
//题库试卷列表
export const testPaperList = (params) => request.post("/getTestPaperList",Qs.stringify(params))
//题库试卷详情
export const testPaperDetail = (params) => request.post("/getPaperDetail",Qs.stringify(params))
//试卷题目列表
export const questionsList = (params) => request.post("/getQuestionsList2",Qs.stringify(params))
//收藏
export const testcollect = (params) => request.post("/testcollect",Qs.stringify(params))
//提交
export const testPaperPracticeQuestionPost = (params) => request.post("/testPaperPracticeQuestionPost",Qs.stringify(params))
//答题结果
export const getQuestionExamResult = (params) => request.post("/getQuestionExamResult",Qs.stringify(params))
//查看解析
export const viewResolution = (params) => request.post("/viewResolution",Qs.stringify(params))
//试卷列表 知识点
export const testPaperKnowledgePointsList = (params) => request.post("/testPaperKnowledgePointsList",Qs.stringify(params))
//每日一练 试卷列表
export const dailyPracticeTestPaper = (params) => request.post("/dailyPracticeTestPaper",Qs.stringify(params))
//校区基本信息
export const branchInfo = (params) => request.post("/branchInfo",Qs.stringify(params))
//题库 做题记录
export const questionRecord = (params) => request.post("/questionRecord",Qs.stringify(params))
//做题记录 删除
export const questionRecordDel = (params) => request.post("/questionRecordDel",Qs.stringify(params))
//错题列表 科目列表
export const questionErrorSubject = (params) => request.post("/questionErrorSubject",Qs.stringify(params))
//错题列表 知识点
export const questionErrorKnowledgePointsList = (params) => request.post("/questionErrorKnowledgePointsList",Qs.stringify(params))
//错题列表
export const questionError = (params) => request.post("/questionError",Qs.stringify(params))
//错题删除
export const questionErrorDel = (params) => request.post("/questionErrorDel",Qs.stringify(params))
//题库答疑添加
export const questionAnswerAdd = (params) => request.post("/questionAnswerAdd",Qs.stringify(params))
//题库答疑列表
export const questionAnswerList = (params) => request.post("/questionAnswerList",Qs.stringify(params))
//答疑详情
export const getQuestionAnswerInfo = (params) => request.post("/getQuestionAnswerInfo",Qs.stringify(params))
//收藏列表
export const questionCollectList = (params) => request.post("/questionCollectList",Qs.stringify(params))
//随堂练习列表
export const commodityTestPaperList = (params) => request.post("/commodityTestPaperList",Qs.stringify(params))
//配套习题 第二版
export const commodityTestPaperList2 = (params) => request.post("/commodityTestPaperList2",Qs.stringify(params))
//随堂练习 提交
export const testPaperPracticeQuestionPostClass = (params) => request.post("/testPaperPracticeQuestionPostClass",Qs.stringify(params))
//随堂练习 做题结果
export const getQuestionExamResultClass = (params) => request.post("/getQuestionExamResultClass",Qs.stringify(params))
//随堂练习 查看解析
export const viewResolutionClass = (params) => request.post("/viewResolutionClass",Qs.stringify(params))
//做题报告
export const studyReport = (params) => request.post("/studyReport",Qs.stringify(params))
//更新在线时间
export const loginTime = (params) => request.post("/loginTime",Qs.stringify(params))
//直播
export const liveBroadcast = (params) => request.post("/liveBroadcastList",Qs.stringify(params))
//未登录 直播
export const liveBroadcast2 = (params) => request.post("/liveBroadcastList2",Qs.stringify(params))
//验证直播
export const liveReservationStatus = (params) => request.post("/liveReservationStatus",Qs.stringify(params))
//直播小程序链接
export const appletUrl = (params) => request.post("/appletUrl",Qs.stringify(params))
export const subscribeUrl = (params) => request.post("/subscribeUrl",Qs.stringify(params))
//课程资料列表 未登录
export const courseDataLogin = (params) => request.post("/courseDataLogin",Qs.stringify(params))
//课程资料列表
export const courseData = (params) => request.post("/courseData",Qs.stringify(params))
//课程资料链接列表
export const courseDataUrlList = (params) => request.post("/courseDataUrlList",Qs.stringify(params))
//记录听课时长
export const coursewareDurationInfo = (params) => request.post("/coursewareDurationInfo",Qs.stringify(params))
//查询课程签约信息
export const commodityAttributeAgree = (params) => request.post("/commodityAttributeAgree",Qs.stringify(params))
//课程签约信息 提交
export const commodityAttributeAgreePost = (params) => request.post("/commodityAttributeAgreePost",Qs.stringify(params))
//题库专题详情
export const testPaperDiscountList = (params) => request.post("/testPaperDiscountList",Qs.stringify(params))
export const testPaperDiscountList2 = (params) => request.post("/testPaperDiscountList2",Qs.stringify(params))
// 视频讲义
export const getCoursewareHandout = (params) => request.post("/getCoursewareHandout",Qs.stringify(params))

export const createOrder= (params)  => request.post('/createOrder',Qs.stringify(params))
